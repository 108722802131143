import React, { useState, useEffect, useRef } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { theme } from '../../styles/theme';
import { FaStar} from 'react-icons/fa';

const reviews = [
  {
    id: 1,
    name: "Catherine Brieger",
    rating: 5,
    message: "When we were holidaying in Sri Lanka i really wanted a souvenir of some beautiful Sri Lankan sapphires and stumbled upon Geo Gems on our day in Galle. We had the incredible experience of ordering a custom-made sapphire ring that was crafted in just one day modelled off an emerald ring they had in store. The turnaround time was astonishing and they delivered to our hotel in the morning before we left. The jewelers were extremely knowledgeable and gave us the best quality sapphires for the price we were willing to spend. The price for quality and service exceeds what you can get in Australia and it was a shame i didn't buy another item as a gift. Excellent & trustworthy service, highly recommend!"
  },
  {
    id: 2,
    name: "Hoda Awwad",
    rating: 5,
    message: "I had a wonderful experience shopping at Geo Gems. The staff were incredibly helpful and knowledgeable, guiding me through the selection process and helping me find the perfect silver ring. The ring itself is beautifully crafted and has quickly become my favorite piece of jewelry. I'm so impressed with the quality and value for money"
  },
  {
    id: 3,
    name: "Mirko Caposiena",
    rating: 5,
    message: "Exceptional! We purchased a custom-made ring, choosing the gemstone, the ring design and the material. In less than 24 hours it was produced and delivered to our hotel. The owner was very patient and very professional. I recommend it to everyone"
  }
];

const MAX_MESSAGE_LENGTH = 150; // Characters to show initially

function CustomerReviews() {
  const [currentReviewIndex, setCurrentReviewIndex] = useState(0);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const [expandedReviews, setExpandedReviews] = useState({});
  const timerRef = useRef(null);

  useEffect(() => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
    
    timerRef.current = setInterval(() => {
      setCurrentReviewIndex((prevIndex) => 
        prevIndex === reviews.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000);

    return () => clearInterval(timerRef.current);
  }, [currentReviewIndex]);

  const handleTouchStart = (e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    
    const distance = touchStart - touchEnd;
    const minSwipeDistance = 50;

    if (Math.abs(distance) < minSwipeDistance) return;

    if (distance > 0) {
      // Swiped left
      setCurrentReviewIndex(prev => 
        prev === reviews.length - 1 ? 0 : prev + 1
      );
    } else {
      // Swiped right
      setCurrentReviewIndex(prev => 
        prev === 0 ? reviews.length - 1 : prev - 1
      );
    }
  };

  const toggleReviewExpansion = (reviewId) => {
    setExpandedReviews(prev => ({
      ...prev,
      [reviewId]: !prev[reviewId]
    }));
  };

  const renderStars = (rating) => {
    return [...Array(5)].map((_, index) => (
      <FaStar
        key={index}
        className={css(index < rating ? styles.starFilled : styles.starEmpty)}
      />
    ));
  };

  const renderMessage = (review) => {
    const isExpanded = expandedReviews[review.id];
    const shouldShowButton = review.message.length > MAX_MESSAGE_LENGTH;
    
    return (
      <div className={css(styles.messageContainer)}>
        <p className={css(styles.message)}>
          {isExpanded ? review.message : 
            shouldShowButton ? `${review.message.slice(0, MAX_MESSAGE_LENGTH)}...` : review.message}
        </p>
        {shouldShowButton && (
          <button 
            onClick={() => toggleReviewExpansion(review.id)}
            className={css(styles.seeMoreButton)}
          >
            {isExpanded ? 'See Less' : 'See More'}
          </button>
        )}
      </div>
    );
  };

  return (
    <div className={css(styles.reviewsContainer)}>
      <h2 className={css(styles.title)}>What Our Customers Say</h2>
      <div className={css(styles.reviewsGrid)}>
        {/* Desktop view */}
        <div className={css(styles.desktopView)}>
          {reviews.map((review) => (
            <div key={review.id} className={css(styles.reviewCard)}>
              <div className={css(styles.reviewHeader)}>
                <div className={css(styles.stars)}>
                  {renderStars(review.rating)}
                </div>
                <p className={css(styles.name)}>~ {review.name}</p>
              </div>
              {renderMessage(review)}
            </div>
          ))}
        </div>
        
        {/* Mobile view */}
        <div className={css(styles.mobileView)}>
          <div className={css(styles.carouselContainer)}
               onTouchStart={handleTouchStart}
               onTouchMove={handleTouchMove}
               onTouchEnd={handleTouchEnd}>
            <div key={reviews[currentReviewIndex].id} className={css(styles.reviewCard)}>
              <div className={css(styles.reviewHeader)}>
                <div className={css(styles.stars)}>
                  {renderStars(reviews[currentReviewIndex].rating)}
                </div>
                <p className={css(styles.name)}>~ {reviews[currentReviewIndex].name}</p>
              </div>
              {renderMessage(reviews[currentReviewIndex])}
            </div>
          </div>
          
          <div className={css(styles.dots)}>
            {reviews.map((_, index) => (
              <div
                key={index}
                className={css(
                  styles.dot,
                  currentReviewIndex === index && styles.activeDot
                )}
                onClick={() => setCurrentReviewIndex(index)}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  reviewsContainer: {
    padding: '2rem',
    backgroundColor: theme.colors.background,
  },
  title: {
    textAlign: 'center',
    marginBottom: '2rem',
    color: theme.colors.primary,
    fontSize: '2rem',
  },
  reviewsGrid: {
    maxWidth: '1200px',
    margin: '0 auto',
  },
  reviewCard: {
    backgroundColor: 'white',
    padding: '1.5rem',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    maxWidth: '90%',
    '@media (max-width: 767px)': {
      minHeight: '200px',
      transform: 'translateX(0)',
      transition: 'transform 0.3s ease-out',
    },
  },
  stars: {
    display: 'flex',
    gap: '0.25rem',
  },
  starFilled: {
    color: '#FFD700',
  },
  starEmpty: {
    color: '#E0E0E0',
  },
  message: {
    fontSize: '1rem',
    lineHeight: '1.5',
    color: theme.colors.text,
    margin: 0,
  },
  name: {
    fontSize: '0.9rem',
    fontWeight: 'bold',
    color: theme.colors.primary,
    margin: 0,
  },
  desktopView: {
    display: 'none',
    '@media (min-width: 768px)': {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
      gap: '2rem',
    },
  },
  mobileView: {
    display: 'none',
    '@media (max-width: 767px)': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
    },
  },
  dots: {
    display: 'flex',
    justifyContent: 'center',
    gap: '0.5rem',
    marginTop: '1rem',
  },
  dot: {
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    backgroundColor: '#E0E0E0',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
  },
  activeDot: {
    backgroundColor: theme.colors.primary,
    transform: 'scale(1.2)',
  },
  carouselContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    touchAction: 'pan-y pinch-zoom',
    overflow: 'hidden',
  },
  seeMoreButton: {
    background: 'none',
    border: 'none',
    color: theme.colors.primary,
    cursor: 'pointer',
    padding: '0.25rem 0',
    fontSize: '0.9rem',
    textDecoration: 'underline',
    alignSelf: 'flex-start',
    ':hover': {
      opacity: 0.8,
    },
  },
  messageContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    flex: 1,
  },
  reviewHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginBottom: '1rem',
  },
});

export default CustomerReviews; 