import React, { useState, useEffect } from 'react';
import { StyleSheet, css } from 'aphrodite';
import TitleSetter from '../TitleSetter';
import { fetchImagesFromS3 } from '../../utils/s3Config';
import { FaFire } from 'react-icons/fa';

function Collections() {
  const [selectedImage, setSelectedImage] = useState(null);
  const [images, setImages] = useState([]);

  useEffect(() => {
    const loadImages = async () => {
      try {
        const fetchedImages = await fetchImagesFromS3(50);
        setImages(fetchedImages);
      } catch (error) {
        console.error('Error loading images:', error);
      }
    };

    loadImages();
  }, []);

  console.log("image", images);
  const openModal = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  const isHotItem = (imageKey) => {
    if (!imageKey) return false;
    return /^1_/.test(imageKey);
  };

  return (
    <>
      <TitleSetter title="Geo Gems - Our Collections" />
      <div className={css(styles.container)}>
        <div className={css(styles.gallery)}>
          {images.map((image, index) => (
            <div 
              key={index} 
              className={css(styles.imageContainer)} 
              onClick={() => openModal(image.url)}
            >
              {isHotItem(image.key) && (
                <div className={css(styles.hotItem)}>
                  <FaFire className={css(styles.fireIcon)} />
                </div>
              )}
              <img src={image.url} alt={`Collection item ${index + 1}`} className={css(styles.image)} />
            </div>
          ))}
        </div>
      </div>
      {selectedImage && (
        <div className={css(styles.modal)} onClick={closeModal}>
          <img src={selectedImage} alt="Enlarged view" className={css(styles.modalImage)} />
        </div>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: '2rem',
    maxWidth: '1200px',
    margin: '0 auto',
    '@media (max-width: 480px)': {
      padding: '0.5rem',
    },
  },
  gallery: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gap: '2rem',
    '@media (max-width: 1024px)': {
      gridTemplateColumns: 'repeat(3, 1fr)',
      gap: '1.5rem',
    },
    '@media (max-width: 768px)': {
      gridTemplateColumns: 'repeat(2, 1fr)',
      gap: '1rem',
    },
    '@media (max-width: 480px)': {
      gridTemplateColumns: 'repeat(3, 1fr)',
      gap: '0.5rem',
      padding: '0.25rem',
    },
  },
  imageContainer: {
    position: 'relative',
    paddingBottom: '100%',
    overflow: 'hidden',
    borderRadius: '8px',
    cursor: 'pointer',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    ':hover': {
      transform: 'translateY(-5px)',
      boxShadow: '0 5px 15px rgba(0,0,0,0.2)',
    },
    '@media (max-width: 480px)': {
      paddingBottom: '100%',
      borderRadius: '4px',
      boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
      ':hover': {
        transform: 'scale(1.05)',
      },
      ':active': {
        transform: 'scale(0.95)',
      },
    },
  },
  image: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    display: 'block',
  },
  modal: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.95)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
    '@media (max-width: 480px)': {
      padding: '0',
    },
  },
  modalImage: {
    maxWidth: '90%',
    maxHeight: '90%',
    objectFit: 'contain',
    '@media (max-width: 480px)': {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
      backgroundColor: 'black',
    },
  },
  hotItem: {
    position: 'absolute',
    top: '10px',
    left: '10px',
    background: '#f8f5f1',
    borderRadius: '50%',
    padding: '8px',
    zIndex: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
    border: '2px solid #d4b996',
    transition: 'all 0.3s ease',
    ':hover': {
      transform: 'scale(1.1)',
      boxShadow: '0 4px 12px rgba(212, 185, 150, 0.4)',
    },
    '@media (max-width: 480px)': {
      top: '3px',
      left: '3px',
      padding: '3px',
      border: '1px solid #d4b996',
    },
  },
  fireIcon: {
    color: '#ff0000',
    fontSize: '20px',
    filter: 'drop-shadow(0 1px 2px rgba(255, 0, 0, 0.3))',
    animation: 'fireAnimation 1.5s infinite',
    '@media (max-width: 480px)': {
      fontSize: '12px',
    },
  },
  '@keyframes fireAnimation': {
    '0%': {
      transform: 'scale(1)',
      color: '#ff0000',
    },
    '50%': {
      transform: 'scale(1.2)',
      color: '#ff3333',
    },
    '100%': {
      transform: 'scale(1)',
      color: '#ff0000',
    },
  },
});

export default Collections;